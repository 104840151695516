<template>
  <div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-start">
      <b-form-group
        label="Search"
        label-size="sm"
        class="form-inline"
      >
        <b-form-input
          v-model="searchTerm"
          class="d-inline-block mr-1"
          placeholder="Search advisor"
          type="text"
        />
        <b-button
          v-b-modal.adapplicationModal
          variant="primary"
        >
          <feather-icon icon="PlusIcon" />
          Add Investments
        </b-button>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
      :rows="rows"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              dropright
              no-caret
              size="sm"
              toggle-class="text-decoration-none"
              variant="link"
            >
              <template v-slot:button-content>
                <feather-icon
                  class="text-body align-middle"
                  icon="MoreVerticalIcon"
                  size="16"
                />
              </template>
              <b-dropdown-item>
                <feather-icon
                  class="mr-50"
                  icon="EyeIcon"
                />
                <span>View Assignment</span>
              </b-dropdown-item>
              <b-dropdown-item>
                <!--@click="selectedRow = props.index; $bvModal.show('partnerModal')" -->

                <feather-icon
                  class="mr-50"
                  icon="UserIcon"
                />
                <span>Assign Investments</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :per-page="pageLength"
              :total-rows="props.total"
              :value="1"
              align="right"
              class="mt-1 mb-0"
              first-number
              last-number
              next-class="next-item"
              prev-class="prev-item"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <b-modal
      id="adapplicationModal"
      size="lg"
      title="Application Form"
    >
      <AdvisorFormBuilder
        v-model="adapplicationForm"
        :inherited-sections="applicationForm"
      />
    </b-modal>
  </div>
</template>
<script>

import {
  BButton, BDropdown, BDropdownItem, BFormGroup, BFormInput, BFormSelect, BPagination,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import gql from 'graphql-tag'
// import vSelect from 'vue-select'
import AdvisorFormBuilder from '@/views/fundings/fundingManager/components/AdvisorFormBuilder.vue'

export default {
  components: {
    BFormInput,
    BFormGroup,
    BButton,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    AdvisorFormBuilder,
    // vSelect,
  },
  data() {
    return {
      searchTerm: '',
      applicationForm: null,
      selectedRow: 0,
      pageLength: 5,
      filter: {
        status: 'Current',
      },
      columns: [
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
        {
          label: 'Index',
          field: 'title',
        },
        {
          label: 'Investments Title',
          field: 'type',
        },
        {
          label: 'Designation',
          field(row) {
            if (!row.programs_operationstable) return '-'
            return row.programs_operationstable.title || '-'
          },
        },
      ],
      rows: [],
    }
  },
  apollo: {
    rows: {
      query() {
        return gql`{
          funding_basicinfo {
           funding_advisors {
            designation
             id
              }
              }
        }`
      },
      update: data => data.funding_basicinfo,
    },

  },
}
</script>
