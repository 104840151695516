<template>
  <section id="card-navigation">
    <b-row>
      <!-- pill tabs inside card -->
      <b-col md="12">
        <b-card class="text-center">
          <b-tabs
            nav-class="mb-3"
            pills
          >
            <b-tab
              class="text-left"
              active
              title="Projects"
            >
              <app-collapse
                accordion
                type="margin"
              />
              <Projects />
            </b-tab>
            <b-tab
              class="text-left"
              title="Requests"
            >
              <Advisors />
            </b-tab>
            <b-tab
              class="text-left"
              title="Programs"
            >
              <Programs />
            </b-tab>
            <b-tab
              class="text-left"
              title="Events"
            >
              <Events />
            </b-tab>
            <b-tab
              class="text-left"
              title="Investments"
            >
              <Investments />
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  // BButton,
  BCard,
  // BCardText,
  // BCardTitle,
  BCol,
  // BFormGroup,
  // BFormInput,
  // BFormSelect,
  // BPagination,
  BRow,
  BTab,
  BTabs,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// import flatPickr from 'vue-flatpickr-component'
// import vSelect from 'vue-select'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
// import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
// import Calendar from '@/views/programs/programManager/components/calendar/Calendar.vue'
import { reactive } from '@vue/composition-api'
// import { VueGoodTable } from 'vue-good-table'
// import FormBuilder from '../components/ProjectsFormBuilder.vue'
import Advisors from '@/views/networks/networkManager/Requests.vue'
import Projects from '@/views/networks/networkManager/Projects.vue'
import Programs from '@/views/networks/networkManager/Programs.vue'
import Events from '@/views/networks/networkManager/Events.vue'
import Investments from '@/views/networks/networkManager/Investments.vue'

export default {
  components: {
    Programs,
    Events,
    Advisors,
    Projects,
    Investments,
    BRow,
    BCol,
    BCard,
    BTab,
    BTabs,
    // BCardText,
    // BCardTitle,
    // BButton,
    // BPagination,
    // BFormGroup,
    // BFormInput,
    // BFormSelect,
    AppCollapse,
    // AppCollapseItem,
    // vSelect,
    // Calendar,
    // FormBuilder,
    // VueGoodTable,
  },
  directives: {
    Ripple,
  },
  setup() {
    const scheduler = reactive({
      programSchedule: null,
      bufferSchedule: null,
    })
    const partner = reactive({
      name: null,
      designation: null,
    })
    const application = reactive({
      rounds: [
        { title: null },
      ],
      pageLength: 5,
      filter: {
        status: 'Current',
      },
      columns: [
        {
          label: 'Trench Number',
          field: 'trenchNumber',
        },
        {
          label: 'Date Approved',
          field: 'dateApproved',
        },
        {
          label: 'Date Disbursed',
          field: 'dateDisbursed',
        },
        {
          label: 'Trench Amount',
          field: 'trenchAmount',
        },
      ],
      rows: [{
        trenchNumber: 3,
        dateApproved: null,
        dateDisbursed: null,
        trenchAmount: '25000',
      },
      {
        trenchNumber: 2,
        dateApproved: '01/01/2019',
        dateDisbursed: null,
        trenchAmount: '25000',

      },
      {
        trenchNumber: 1,
        dateApproved: '01/01/2019',
        dateDisbursed: '01/03/2019',
        trenchAmount: '25000',
      },
      ],
      searchTerm: '',
      status: [{
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
      {
        1: 'light-primary',
        2: 'light-success',
        3: 'light-danger',
        4: 'light-warning',
        5: 'light-info',
      }],
    })
    return {
      scheduler,
      partner,
      application,
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
